import { getIntegerCurrencyString } from '@/helpers/currency-utils';

export const formatCouponDuration = (coupon, forStyle = 'x', monthStyle = 'month') => {
  if (!coupon) return '';
  if (coupon.couponDuration === null) {
    return 'forever';
  }
  return `${forStyle} ${coupon.couponDuration} ${monthStyle}${coupon.couponDuration > 1 ? 's' : ''}`;
};

export const formatCouponAmount = (coupon) => {
  if (!coupon) return '';
  if (!coupon.couponAmount) return '';
  if (coupon.couponType === 'PERCENT') {
    return `${coupon.couponAmount}%`;
  }
  return getIntegerCurrencyString(coupon.couponAmount, coupon.currency, false);
};

export const formatCouponDescription = (coupon) => {
  if (!coupon) return '';
  return `${formatCouponAmount(coupon)} off ${formatCouponDuration(coupon, 'for')}`;
};

export const formatCouponDescriptionShort = (coupon) => {
  if (!coupon) return '';
  return `${formatCouponAmount(coupon)} ${formatCouponDuration(coupon, 'x', 'mo')}`;
};
